import React, { useContext, useEffect, useRef, useState } from "react";
import axiosConfig from '../../axiosConfig';
import network from "../../../constants/Network";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";
import ChatUI from "../../components/ChatUI/ChatUI";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';

const RecentProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [showMore, setShowMore] = useState([]);
  const [count, setCount] = useState(10);
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const isMountedRef = useRef(false);
  const gContext = useContext(GlobalContext);
  // const { user } = useContext(GlobalContext);
  const [showMenuProfileId, setShowMenuProfileId] = useState(null);

  const [userId, setUserId] = useState('');

  const toggleMenuButton = (id) => {
    console.log('toggle report button clicked:', id);

    setShowMenuProfileId(showMenuProfileId === id ? null : id)
  }

  const { user, isChatVisible, setJobProviderId, setJobSeekerId, handleClose, handleOpenChat } = useContext(GlobalContext);

  const styles = RecentProfilesStyling();

  // const toggleShowMore = (id) => {
  //   if (showMore.includes(id)) {
  //     setShowMore(showMore.filter(i => i !== id));
  //   } else {
  //     setShowMore([...showMore, id]);
  //   }
  // };

  // const handleLoadMore = async () => {
  //   try {
  //     const newCount = count + 10;
  //     setCount(newCount);
  //     await fetchProfiles(newCount);
  //   } catch (error) {
  //     console.error('Error loading more profiles:', error);
  //   }
  // };

  const fetchProfiles = async (count) => {
    try {

      if (!userId) {
        console.log('No user found for profiles fetching');
        return
      }

      const response = await axios.post(`${network.serverip}/api/recent-profilelist-forweb`, { count, userId });
      if (response.data.success && isMountedRef.current) {
        setProfiles(response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setProfiles([]);
        }
        console.error('Failed to fetch profiles');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setProfiles([]);
      }
      console.error('Error fetching profiles:', error);
    }
  };

  const handleChatButtonClick = async (jobSeekerId) => {
    try {
      const job_provider_id = user?.id;

      if (!job_provider_id || !jobSeekerId) {
        console.error("Missing job provider or job seeker information");
        return;
      }

      setJobProviderId(job_provider_id); // Update context with the job provider ID
      setJobSeekerId(jobSeekerId); // Update context with the job seeker ID

      const response = await axiosConfig.post(`/chat-open/`, { job_seeker_id: jobSeekerId, job_provider_id });

      if (response.data.success) {
        console.log("Chat opened successfully:", response.data.data);
      } else {
        console.error("Failed to open chat:", response.data.message);
      }
    } catch (error) {
      console.error("Error opening chat:", error);
    }

    if (!isChatVisible) {
      handleOpenChat();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfiles(10);
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <>
      <style>
        {`
          .link-hover-text-color {
            color: #1e1e20;
            text-decoration: none;
          }
        `}
      </style>
      {/* <!-- FeaturedJobs Area -->  */}
      <div className="pt-11 pt-lg-27 pb-7 pb-lg-26 bg-black-2 dark-mode-texts">
        <div className="container">
          {/* <!-- Section Top --> */}
          <div className="row align-items-center pb-14">
            {/* <!-- Section Title --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-left mb-13 mb-lg-0">
                <h2 className="font-size-9 font-weight-bold">Recent Profiles</h2>
              </div>
            </div>
            {/* <!-- Section Button --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-right">
                <Link to="/recent-profilelisting-grid" className="btn btn-outline-white text-uppercase">
                  Explore All
                </Link>
              </div>
            </div>
            {/* <!-- Section Button End --> */}
          </div>
          {/* <!-- End Section Top --> */}
          <div className="row justify-content-center">
            {/* Map over the jobs array to render each job card dynamically */}
            {profiles.map((profile, index) => (
              <div
                key={index}
                className="col-12 col-lg-4 col-md-6 px-xxl-7"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {/* Start Job Card */}
                <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                  <div className="d-block mb-7">
                    <div style={styles.imageContainer}>
                      <button
                        onClick={() => gContext.toggleImageViewModal(profile?.image)}
                        style={styles.imageViewButton}
                      >
                        <img
                          src={profile && profile.image ? imageBaseUrl + profile.image : imageBaseUrl + "profile_default_photo.png"}
                          alt=""
                          style={styles.profileImage}
                        />
                      </button>
                      {showMenuProfileId === profile?.id &&
                        <>
                          {profile?.isReported === 1 ?
                            (
                              <button
                                style={styles.reportButton}
                                className="font-size-4 text-gray"
                              >
                                <i className="fas fa-info-circle text-gray"></i>
                                <span className="text-gray ml-5">Already Reported</span>
                              </button>
                            ) : (
                              <button
                                onClick={() => { gContext.toggleReportProfileModal(profile?.user_id) }}
                                style={styles.reportButton}
                                className="font-size-4 text-danger"
                              >
                                <i className="fas fa-info-circle text-danger"></i>
                                <span className="text-danger ml-5">Report</span>
                              </button>
                            )
                          }
                        </>
                      }
                      <button
                        onClick={() => { toggleMenuButton(profile?.id) }}
                        style={styles.openMenuIcon}
                      >
                        <i className="fas fa-ellipsis-v"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h2 className="mt-n4">
                      <Link
                        to={`/profile-details-view/?id=${profile.id}`}
                        className="font-size-6 font-weight-bold mb-2 link-hover-text-color"
                      >
                        {profile && profile.name ? profile.name : ''}
                      </Link>
                    </h2>
                    <div className="mt-n4">
                      <p className="font-size-3 font-weight-bold text-black-2 mb-4">
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faUserMd} className="text-success" />
                        </span>
                        {profile && profile.role ? profile.role : ''}
                      </p>
                    </div>
                  </div>
                  <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    {/* <li style={styles.jobTypeBox}>
                      <div
                        className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                      >
                        <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                        {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                      </div>
                    </li> */}
                    {profile && (profile.city || profile.state) && (
                      <li style={styles.locationBox}>
                        <div
                          className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                        >
                          <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                          {profile.city || ''}{profile.city && profile.state ? ', ' : ''}{profile.state || ''}
                        </div>
                      </li>
                    )}
                    {profile?.created_at && (
                      <li style={styles.createdDateBox}>
                        <div
                          className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                        >
                          {new Date(profile.created_at).toLocaleDateString('default', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          }).replace(/ /g, '-')}
                        </div>
                      </li>
                    )}
                  </ul>
                  <p className="mb-2" style={{ textAlign: 'justify' }}>
                    {profile && profile.about_me ? (
                      <span className="font-size-4 text-black-2" >
                        {((showMore.includes(profile.id)) || profile.about_me.length <= 150)
                          ? `${profile.about_me} `
                          : `${profile.about_me.slice(0, 150)}... `}
                        {profile.about_me.length > 150 && (
                          // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                          //     {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                          // </span>
                          <Link to={`/profile-details-view/?id=${profile.id}`} style={styles.showMoreButton}>
                            {'Show more'}
                          </Link>
                        )}
                      </span>
                    ) : (
                      null
                    )}
                  </p>
                  <div className="w-100 mb-2">
                    {/* {job?.posted_by?.name && (
                      <p className="font-size-3 text-gray mb-0">
                        {`Posted by: ${job.posted_by.name}`}
                      </p>
                    )} */}
                  </div>
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5"
                      onClick={() => handleChatButtonClick(profile.id)} // Pass profile.id as jobSeekerId
                    >
                      <FontAwesomeIcon icon={faComments} className="font-weight-bold mr-4 font-size-4" />{" "}
                      Chat
                    </button>
                  </div>
                </div>
                {/* End Job Card */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentProfiles;

function RecentProfilesStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    imageContainer: {
      width: '100%',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f5f5f5',
    },
    profileImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    jobTypeBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    locationBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    salaryBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    createdDateBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
      display: 'flex',
      justifyContent: 'center',
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      width: '100%',
      height: '100%',
      padding: '0',
    },

    openMenuIcon: {
      position: 'absolute',
      right: '2.9rem',
      top: '2rem',
      background: 'white',
      border: 'none',
      borderRadius: '3px',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },

    reportButton: {
      position: 'absolute',
      right: '4.2rem',
      top: '1.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }

  };

  return styles;
}
