import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import imgC1 from "../../assets/image/l3/png/content-2-img1.png";
import imgC2 from "../../assets/image/l3/png/content-2-img2.png";
import imgC3 from "../../assets/image/l3/png/content-2-img3.png";
import bgImage from "../../assets/final animation.gif"; // Adjust path accordingly

import imgS1 from "../../assets/image/svg/shoot.svg";
import imgS2 from "../../assets/image/svg/user.svg";
import imgS3 from "../../assets/image/svg/heart.svg";
const Content = () => {
  const [gifSrc, setGifSrc] = useState(""); // Initially empty

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("animation");
      const prevSection = document.querySelector(".opacitycls");

      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          document.body.classList.add("scrolled");
          setGifSrc("http://192.168.235.211:8000/images/final animation.gif"); // Load GIF when in view
          if (prevSection) {
            prevSection.style.opacity = "0"; // Hide previous section
            prevSection.style.zIndex = "0"; 
          }
        } else {
          document.body.classList.remove("scrolled");
          setGifSrc(""); // Remove GIF when out of view
          if (prevSection) {
            prevSection.style.opacity = "1"; // Restore previous section
            prevSection.style.zIndex = "1";
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <style>
        {`
          body.scrolled {
            background-color: white;
            transition: background-color 0.3s ease-in-out;
          }
          .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .centered-image {
            width: 70%;
            transition: opacity 0.3s ease-in-out;
          }
        `}
      </style>
      {/* <!-- Content Area --> */}
      <section className="pt-7 pt-lg-16 pb-2" id="animation">
        <div className="container">
          <div className="row pb-lg-6 justify-content-center">
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              {/* <!-- content-1 left-content start --> */}
              <div className="position-relative pr-xl-20 pr-md-15 pr-15 image-container">
                {/* <!-- content img start --> */}
                <img
                  src={gifSrc} // Dynamically load GIF
                  alt=""
                  className="centered-image rounded overflow-hidden"
                />
                {/* <!-- content img end --> */}
              </div>
              {/* <!-- content-1 left-content end --> */}
            </div>
          </div>
        </div>
      </section>
	   <div className="pt-11 pt-lg-20 pb-7 pb-lg-18">
        <div className="container">
          {/* <!-- Section title --> */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
              <div className="text-center mb-8 mb-lg-18 px-xl-9 px-xxl-7">
                <h2 class="font-size-10 font-weight-bold mb-8">How It Works</h2>
                
              </div>
            </div>
          </div>
          {/* <!-- End Section title --> */}
          {/* <!-- Services Content --> */}
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {/* <!-- Single Services --> */}
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 rounded-4 bg-dodger text-white font-size-8 mx-auto shadow-dodger mb-11">
                  <img src={imgS1} alt="" />
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">Register & Verify</h3>
                  <p className="font-size-4 text-default-color">
Sign up quickly by entering your details and verifying your account using an OTP sent to your mobile or email. This ensures a secure and seamless experience.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Single Services --> */}
            {/* <!-- Single Services --> */}
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 rounded-4 bg-green text-white font-size-8 mx-auto shadow-green mb-11">
                  <img src={imgS2} alt="" />
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">Login & Explore</h3>
                  <p className="font-size-4 text-default-color">
                    Log in to access your personalized dashboard, where you can browse hospitals, job listings, and medical professionals based on your preferences.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Single Services --> */}
            {/* <!-- Single Services --> */}
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 rounded-4 bg-casablanca text-white font-size-8 mx-auto shadow-casablanca mb-11">
                  <img src={imgS3} alt="" />
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">Connect & Apply </h3>
                  <p className="font-size-4 text-default-color">
                   Find locum or permanent job opportunities that match your expertise. Apply instantly, communicate with recruiters, and take the next step in your medical career with ease
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Single Services --> */}
          </div>
          {/* <!-- End Services Content --> */}
        </div>
      </div>
    </>
  );
};

export default Content;
