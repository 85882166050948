// import React, { useEffect, useState } from "react";
// import axiosConfig from '../../axiosConfig';

// import { Link } from "gatsby";
// import imgF1 from "../../assets/image/l1/png/feature-brand-1.png";
// import imgF2 from "../../assets/image/l1/png/feature-brand-2.png";
// import imgF3 from "../../assets/image/l1/png/feature-brand-3.png";
// import imgF4 from "../../assets/image/l1/png/feature-brand-4.png";
// import imgF5 from "../../assets/image/l1/png/feature-brand-5.png";
// import imgF6 from "../../assets/image/l1/png/feature-brand-6.png";

// const FeaturedJobs = () => {
//   const [jobs, setjobs] = useState([]);
//   useEffect(() => {
//     axiosConfig.get('/job-listforweb')
//       .then(response => {
//         if (response.data.success) {
//           setjobs(response.data.data);
//         } else {
//           console.error('Failed to fetch categories');
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching categories:', error);
//       });
//   }, []);
//   return (
//     <>
//       {/* <!-- FeaturedJobs Area -->  */}
//       <div className="pt-11 pt-lg-27 pb-7 pb-lg-26 bg-black-2 dark-mode-texts">
//         <div className="container">
//           {/* <!-- Section Top --> */}
//           <div className="row align-items-center pb-14">
//             {/* <!-- Section Title --> */}
//             <div className="col-12 col-xl-6 col-lg-6">
//               <div className="text-center text-lg-left mb-13 mb-lg-0">
//                 <h2 className="font-size-9 font-weight-bold">Featured Jobs</h2>
//               </div>
//             </div>
//             {/* <!-- Section Button --> */}
//             <div className="col-12 col-xl-6 col-lg-6">
//               <div className="text-center text-lg-right">
//                 <Link to="/#" className="btn btn-outline-white text-uppercase">
//                   Explore All
//                 </Link>
//               </div>
//             </div>
//             {/* <!-- Section Button End --> */}
//           </div>
//           {/* <!-- End Section Top --> */}
//           <div className="row justify-content-center">
//       {/* Map over the jobs array to render each job card dynamically */}
//       {jobs.map((job, index) => (
//         <div
//           key={index}
//           className="col-12 col-lg-4 col-md-6 px-xxl-7"
//           data-aos="fade-up"
//           data-aos-duration="800"
//         >
//           {/* Start Job Card */}
//           <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
//             <div className="d-block mb-7">
//               <Link to="/#">
//                 <img src={job.imgSrc} alt="" />
//               </Link>
//             </div>
//             <Link to="/#" className="font-size-3 d-block mb-0 text-gray">
//               {job.company}
//             </Link>
//             <h2 className="mt-n4">
//               <Link
//                 to="/#"
//                 className="font-size-7 text-black-2 font-weight-bold mb-4"
//               >
//                 {job.title}
//               </Link>
//             </h2>
//             <ul className="list-unstyled mb-1 card-tag-list">
//               <li>
//                 <Link
//                   to="/#"
//                   className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
//                 >
//                   <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
//                   {job.location}
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/#"
//                   className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
//                 >
//                   <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
//                   {job.type}
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/#"
//                   className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
//                 >
//                   <i className="fa fa-dollar-sign mr-2 font-weight-bold"></i>{" "}
//                   {job.salary}
//                 </Link>
//               </li>
//             </ul>
//             <p className="mb-7 font-size-4 text-gray">{job.description}</p>
//             <div className="card-btn-group">
//               <Link
//                 to="/#"
//                 className="btn btn-green text-uppercase btn-medium rounded-3"
//               >
//                 Apply Now
//               </Link>
//               <Link
//                 to="/#"
//                 className="btn btn-outline-mercury text-black-2 text-uppercase btn-medium rounded-3"
//               >
//                 <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
//                 Save it
//               </Link>
//             </div>
//           </div>
//           {/* End Job Card */}
//         </div>
//       ))}
//     </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FeaturedJobs;

import React, { useContext, useEffect, useRef, useState } from "react";
import axiosConfig from '../../axiosConfig';
import network from "../../../constants/Network";
import { Link } from "gatsby";
import GlobalContext from "../../context/GlobalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';

const FeaturedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [showMore, setShowMore] = useState([]);
  const [count, setCount] = useState(10);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const isMountedRef = useRef(false);
  const gContext = useContext(GlobalContext);
  const { user } = useContext(GlobalContext);
  const [showMenuJobId, setShowMenuJobId] = useState(null);

  const [userId, setUserId] = useState('');

  const styles = FeaturedJobsStyling();

  const toggleShowMore = (id) => {
    if (showMore.includes(id)) {
      setShowMore(showMore.filter(i => i !== id));
    } else {
      setShowMore([...showMore, id]);
    }
  };

  const toggleMenuButton = (id) => {
    setShowMenuJobId(showMenuJobId === id ? null : id)
  }

  // const handleLoadMore = async () => {
  //   try {
  //     const newCount = count + 10;
  //     setCount(newCount);
  //     await fetchFeaturedJobs(newCount);
  //   } catch (error) {
  //     console.error('Error loading more jobs:', error);
  //   }
  // };

  const fetchFeaturedJobs = async (search, count) => {
    try {

      // if (!userId) {
      //   console.log('No user found for job fetching');
      //   return
      // }

      const response = await axiosConfig.post('/featuredjoblist-forweb', { search, count, userId });
      if (response.data.success && isMountedRef.current) {
        setJobs(response.data.data);
        setTotalJobCount(response.data.total);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setJobs([]);
          setTotalJobCount(0);
        }
        console.error('Failed to fetch jobs');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setJobs([]);
        setTotalJobCount(0);
      }
      console.error('Error fetching jobs:', error);
    }
  };

  const handleJobSaveOrUnsave = async (jobId) => {
    try {

      if (!userId) {
        gContext.toggleSignInModal();

        return
      }

      if (userId && jobId) {
        const response = await axiosConfig.post(`/saveOrUnsaveJob-web`, { userId, jobId });

        if (response.data.success && isMountedRef.current) {

          setJobs((prevState) =>
            prevState.map((job) =>
              job.id === jobId
                ? { ...job, isSaved: job.isSaved === 1 ? 0 : 1 }
                : job
            )
          );

          console.log('Job saved / unsaved');
        } else {
          if (isMountedRef.current) {
            console.log('Failed to save / unsave job');
          }
          console.error('Failed to save / unsave job:', response.data.message);
        }
      } else {
        console.error('userId or jobId is missing');
      }
    } catch (error) {
      console.error('Failed to save/ unsave job:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchFeaturedJobs('', 10);
    };

    fetchData();
  }, [userId]);

  return (
    <>
      <style>
        {`
          .link-hover-text-color {
            color: #1e1e20;
            text-decoration: none;
          }
        `}
      </style>
      {/* <!-- FeaturedJobs Area -->  */}
      <div className="pt-11 pt-lg-27 pb-7 pb-lg-26 bg-black-2 dark-mode-texts">
        <div className="container">
          {/* <!-- Section Top --> */}
          <div className="row align-items-center pb-14">
            {/* <!-- Section Title --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-left mb-13 mb-lg-0">
                <h2 className="font-size-9 font-weight-bold">Featured Jobs</h2>
              </div>
            </div>
            {/* <!-- Section Button --> */}
            <div className="col-12 col-xl-6 col-lg-6">
              <div className="text-center text-lg-right">
                {gContext?.user?.id ? (
                  <Link to="/featured-joblisting-grid" className="btn btn-outline-white text-uppercase">
                    Explore All
                  </Link>
                ) : (
                  <button
                    className="btn btn-outline-white text-uppercase"
                    onClick={() => { gContext.toggleSignInModal() }}
                  >
                    Explore All
                  </button>
                )}
              </div>
            </div>
            {/* <!-- Section Button End --> */}
          </div>
          {/* <!-- End Section Top --> */}
          <div className="row justify-content-center">
            {/* Map over the jobs array to render each job card dynamically */}
            {jobs.map((job, index) => (
              <div
                key={index}
                className="col-12 col-lg-4 col-md-6 px-xxl-7"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {/* Start Job Card */}
                <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                  <div className="d-block mb-7">
                    <div style={styles.imageContainer}>
                      <button
                        onClick={() => gContext.toggleImageViewModal(job?.image)}
                        style={styles.imageViewButton}
                      >
                        <img
                          src={job && job.image ? imageBaseUrl + job.image : ""}
                          alt=""
                          style={styles.jobImage}
                        />
                      </button>
                      {showMenuJobId === job?.id &&
                        <>
                          {job?.isReported === 1 ?
                            (
                              <button
                                style={styles.reportButton}
                                className="font-size-4 text-gray"
                              >
                                <i className="fas fa-info-circle text-gray"></i>
                                <span className="text-gray ml-5">Already Reported</span>
                              </button>
                            ) : (
                              <button
                                onClick={() => { gContext.toggleReportJobModal(job?.id) }}
                                style={styles.reportButton}
                                className="font-size-4 text-danger"
                              >
                                <i className="fas fa-info-circle text-danger"></i>
                                <span className="text-danger ml-5">Report</span>
                              </button>
                            )
                          }
                        </>
                      }
                      <button
                        onClick={() => { toggleMenuButton(job?.id) }}
                        style={styles.openMenuIcon}
                      >
                        <i className="fas fa-ellipsis-v"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h2 className="mt-n4">
                      <Link
                        to={`/job-details/?id=${job.id}`}
                        className="font-size-6 font-weight-bold mb-2 link-hover-text-color"
                      >
                        {job && job.title ? job.title : ''}
                      </Link>
                    </h2>
                    <div className="mt-n4">
                      <p className="font-size-3 font-weight-bold text-black-2 mb-4">
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faHospitalAlt} />
                        </span>
                        {job && job.institution && job.institution.title ? job.institution.title : ''}
                      </p>
                    </div>
                  </div>
                  <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <li style={styles.jobTypeBox}>
                      <div
                        className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                      >
                        <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                        {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                      </div>
                    </li>
                    <li style={styles.locationBox}>
                      <div
                        className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                      >
                        <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                        {job && job.location && job.location.title ? job.location.title : ''}
                        {job && job.state && job.state.title ? `, ${job.state.title}` : ''}
                      </div>
                    </li>
                    {job?.created_at && (
                      <li style={styles.createdDateBox}>
                        <div
                          className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                        >
                          {new Date(job.created_at).toLocaleDateString('default', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          }).replace(/ /g, '-')}
                        </div>
                      </li>
                    )}
                    {/* {job?.salary && job.salary > 0 && (
                      <li style={styles.salaryBox}>
                        <div
                          className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                        >
                          <span className="mr-2">₹</span>
                          {job && job.salary ? `${job.salary}/month` : ''}
                        </div>
                      </li>
                    )} */}
                  </ul>
                  <p className="mb-2" style={{ textAlign: 'justify' }}>
                    {job && job.description ? (
                      <span className="font-size-4 text-black-2">
                        {((showMore.includes(job.id)) || job.description.length <= 150)
                          ? `${job.description} `
                          : `${job.description.slice(0, 150)}... `}
                        {job.description.length > 150 && (
                          // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                          //   {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                          // </span>
                          <Link to={`/job-details/?id=${job.id}`} style={styles.showMoreButton}>
                            {'Show more'}
                          </Link>
                        )}
                      </span>
                    ) : (
                      null
                    )}
                  </p>
                  <div className="w-100 mb-2">
                    {job?.posted_by?.name && (
                      <p className="font-size-3 text-gray mb-0">
                        {`Posted by: ${job.posted_by.name}`}
                      </p>
                    )}
                  </div>
                  <div className="card-btn-group d-flex justify-content-between">
                    {job?.isApplied === 1 ? (
                      <button className="btn btn-gray text-uppercase btn-medium rounded-3" disabled>
                        Applied
                      </button>
                    ) : (
                      <Link
                        to={`/job-apply/?id=${job?.id}`}
                        className="btn btn-green text-uppercase btn-medium rounded-3"
                      >
                        Apply
                      </Link>
                    )}
                    {job && job.isSaved && job.isSaved === 1 ? (
                      <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                        className="btn btn-outline-mercury text-uppercase h-px-48 rounded-3 mb-5 px-5">
                        <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                        <span className="text-success">Saved</span>
                      </button>
                    ) : (
                      <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                        className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5">
                        <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                        Save job
                      </button>
                    )}
                  </div>
                </div>
                {/* End Job Card */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedJobs;

function FeaturedJobsStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    imageContainer: {
      width: '100%',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f5f5f5',
    },
    jobImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    jobTypeBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    locationBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    salaryBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
    },
    createdDateBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
      margin: '5px',
      display: 'flex',
      justifyContent: 'center',
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      width: '100%',
      height: '100%',
      padding: '0',
    },
    openMenuIcon: {
      position: 'absolute',
      right: '2.9rem',
      top: '2rem',
      background: 'white',
      border: 'none',
      borderRadius: '3px',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },
    reportButton: {
      position: 'absolute',
      right: '4.2rem',
      top: '1.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }


  };

  return styles;
}
