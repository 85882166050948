import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosConfig from '../../axiosConfig';
import { Select } from "../../components/Core";


const Hero = () => {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    axiosConfig.get("/sliderwebsite")
      .then(response => {
        if (response.data.success) {
          console.log("Fetched slider data:", response.data.data);
          setSliderData(response.data.data);
        } else {
          console.error("Failed to fetch slider data");
        }
      })
      .catch(error => {
        console.error("Error fetching slider data:", error);
      });
  }, []);

  if (sliderData.length === 0) return <p>Loading slider...</p>;

  // Slick slider settings
  const settings = {
  dots: false,
  infinite: true,
  speed: 1000, // Smooth transition speed
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  fade: true, // Enable fade transition
};


  return (
    <section className="slider-container">
      {/* Slider Component */}
      <div className="slider-wrapper">
        <Slider {...settings}>
          {sliderData.map((slide, index) => (
            <div key={index} className="slider-item">
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="slider-image"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Title BELOW the Slider */}
      <div className="slider-title">
        <h2>{sliderData[0]?.title}</h2>
      </div>

      <style jsx>{`
        .slider-container {
          width: 100%;
          margin-top: 95px;
          
        }

        /* Slider takes full width */
        .slider-wrapper {
          width: 100%;
        }

        .slider-image {
          width: 100%;
          height: 800px;
          object-fit: cover;
          
        }

        /* Title BELOW the slider, aligned to the left */
        .slider-title {
          width: 100%;
          text-align: left;
          margin-top: 20px;
		  margin-left:25px;
        }

        .slider-title h2 {
          font-size: 40px;
          font-weight: bold;
          color: #fecb08;
          animation: fadeIn 1s ease-in-out;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @media (max-width: 768px) {
          .slider-container {

          }

          .slider-title h2 {
            font-size: 30px;
            text-align: left;
          }
        }
      `}</style>
    </section>
  );
};

export default Hero;