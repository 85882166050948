import React, { useEffect, useState } from "react";
import axiosConfig from '../../axiosConfig';

import imgC from "../../assets/image/l1/jpg/content-1-img1.jpg";
import imgL1 from "../../assets/image/l1/png/smal-logo1.png";
import imgL2 from "../../assets/image/l1/png/smal-logo2.png";
import imgL3 from "../../assets/image/l1/png/smal-logo3.png";
import imgL4 from "../../assets/image/l1/png/smal-logo4.png";
import imgL5 from "../../assets/image/l1/png/smal-logo5.png";

const Content = () => {
	
	const [aboutData, setAboutData] = useState({ title: "", image: "" });
  useEffect(() => {
	axiosConfig.get('/homeabout')
      .then(response => {
        if (response.data.success) {
			console.log(response.data.data);
          setAboutData(response.data.data);
          
        } else {
          console.error('Failed to fetch  text');
        }
      })
      .catch(error => {
        console.error('Error fetching  text:', error);
      });
  }, []);
	
  return (
    <>
      {/* <!-- Content Area -->  */}
      <section className=" ">
        <div className="container">
          <div className="row  justify-content-center">
            <div
              className="col-xl-6 col-lg-5 col-md-10 col-sm-11"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              {/* <!-- content-1 left-content start --> */}
              <div className="position-relative ">
                {/* <!-- content img start --> */}
                <img
                  src={aboutData.image}
                  alt=""
                  className="w-100 rounded overflow-hidden opacitycls"
                />
                {/* <!-- content img end --> */}
                {/* <!-- abs-content start --> */}
               
                {/* <!-- abs-content end --> */}
              </div>
              {/* <!-- content-1 left-content end --> */}
            </div>
            <div
              className="col-xl-6 col-lg-7 col-md-8"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              {/* <!-- content-1 start --> */}
              <div className=" d-flex flex-column justify-content-center h-100  ">
                {/* <!-- content-1 section-title start --> */}
                <h2 className="font-size-9 mb-8 pr-xxl-15">
                   {aboutData.title}
                </h2>
                <p className="text-gray font-size-5 mb-10 mb-lg-16">
                   {aboutData.description}
                </p>
                
              </div>
              {/* <!-- content-1 end --> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
